import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useCallback, useEffect, useMemo, useState, } from 'react';
import { InputAdornment, Slider, useTheme } from '@mui/material';
import Input from '@shared/ui/inputs/Input';
import { ContainerColumn, ContainerRow } from '@components/styled';
import { ActiveText, Body, Subtitle } from '@components/styled/Typography';
import { debounceWrapper } from '@utils/Helpers';
const Container = styled(ContainerColumn)(() => ({
    height: 'auto',
}));
const SliderInputContainer = styled(ContainerColumn)(() => ({
    position: 'relative',
    height: 'auto',
}));
const SliderStyled = styled(Slider)(props => ({
    position: 'absolute',
    bottom: '-13px',
    borderTopLeftRadius: 0,
    '& .MuiSlider-rail': {
        display: 'none',
    },
    '& .MuiSlider-thumb': {
        width: `${props.theme.spacing_sizes.xs * 2}px`,
        height: `${props.theme.spacing_sizes.xs * 2}px`,
        boxShadow: 'none',
        '&:before': {
            display: 'none',
        },
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxSizing: 'content-box',
            border: '3px solid #D8E0F8',
            boxShadow: 'none',
        },
    },
    '& .MuiSlider-valueLabel': {
        '&:before': {
            display: 'none',
        },
        background: 'black',
        padding: `${props.theme.spacing_sizes.s / 2}px ${props.theme.spacing_sizes.s}px`,
        borderRadius: `${props.theme.spacing_sizes.s}px`,
        '&.MuiSlider-valueLabelOpen': {
            transform: 'translate(0%, -70%)',
            '&:before': {
                display: 'none',
            },
        },
    },
}));
const MarksContainer = styled(ContainerRow)(props => ({
    height: 'auto',
    justifyContent: 'space-between',
    marginTop: `${props.theme.spacing_sizes.xs}px`,
}));
const Mark = styled(ActiveText)(props => ({
    fontWeight: 400,
    color: `${props.theme.palette.text.secondary}`,
}));
const SliderInputAdornment = styled(Subtitle)(() => ({
    fontWeight: 400,
}));
const SliderLabel = styled(Body)((props) => ({
    fontWeight: 500,
    marginBottom: `${props.theme.spacing_sizes.xs * 1.25}px`,
}));
const ValidateInputDebounceDelay = 500;
const SliderInput = ({ value, onChange, minMaxValues, label = undefined, }) => {
    const theme = useTheme();
    const [currentValue, setCurrentValue] = useState(value);
    useEffect(() => {
        setCurrentValue(value);
    }, [value]);
    const validateInput = useCallback((v) => {
        const [min, max] = minMaxValues;
        let newValue = v;
        if (newValue < min) {
            newValue = min;
        }
        else if (newValue > max) {
            newValue = max;
        }
        setCurrentValue(newValue);
        onChange(newValue);
    }, [minMaxValues, setCurrentValue, onChange]);
    const validateInputDebounce = useMemo(() => debounceWrapper(validateInput, ValidateInputDebounceDelay), [validateInput]);
    const onChangeHandler = (event) => {
        const newValue = Number(event.target.value) || 0;
        setCurrentValue(newValue);
        validateInputDebounce(newValue);
    };
    const onSetAllClick = () => {
        onChange(minMaxValues[1]);
    };
    const onSliderChange = (event, newValue) => {
        if (typeof newValue === 'number') {
            onChange(newValue);
        }
    };
    return (_jsxs(Container, { children: [label ? _jsx(SliderLabel, { children: label }) : null, _jsxs(SliderInputContainer, { children: [_jsx(Input, { size: 'medium', value: currentValue, onChange: onChangeHandler, InputProps: {
                            startAdornment: (_jsx(InputAdornment, { position: 'start', children: _jsx(SliderInputAdornment, { children: "USDT" }) })),
                            endAdornment: (_jsx(InputAdornment, { position: 'end', children: _jsx(SliderInputAdornment, { style: { cursor: 'pointer' }, color: theme.palette.primary.main, onClick: onSetAllClick, children: "All" }) })),
                        } }), _jsx(SliderStyled, { size: 'medium', min: minMaxValues[0], max: minMaxValues[1], value: value, onChange: onSliderChange, valueLabelDisplay: 'auto' })] }), _jsxs(MarksContainer, { children: [_jsx(Mark, { children: minMaxValues[0] }), _jsx(Mark, { children: minMaxValues[1] })] })] }));
};
export default SliderInput;
